<template>
  <section class="blogposts">
    <div class="container">
      <div class="row text-center pb-4">
        <div class="col-md-12 pt-2">
          <p class="pt-2">Blog #Insights</p>
          <h1>Wisdom Schema blogs</h1>
          <p>
            Discover innovation stories from our leading bloggers. From basic
            insights to strategy report, this is the place where new ideas come
            alive.
          </p>
        </div>
      </div>
      <div class="row pt-4">
        <div class="container">
          <div class="tabsblogs">
            <a
              class="tabsHeading"
              @click="activetab = '1'"
              :class="[activetab === '1' ? 'active' : '']"
              >View all</a
            >
            <a
              class="tabsHeading"
              @click="activetab = '2'"
              :class="[activetab === '2' ? 'active' : '']"
              >Category one</a
            >
            <a
              class="tabsHeading"
              @click="activetab = '3'"
              :class="[activetab === '3' ? 'active' : '']"
              >Category two
            </a>
            <a
              class="tabsHeading"
              @click="activetab = '4'"
              :class="[activetab === '4' ? 'active' : '']"
              >Category three</a
            >
          </div>

          <div class="content">
            <div v-if="activetab === '1'" class="tabcontent">
              <div class="container">
                <div class="row">
                  <div
                    class="col-lg-4 col-md-6 col-12 d-flex align-items-stretch"
                    v-for="(obj, key) in viewAllJSON"
                    :key="key"
                  >
                    <div class="icon-box">
                      <Image :fpath="obj.path" class="serviceimage" />
                      <p class="d-flex">
                        <span class="category">{{ obj.category }}</span>
                        <span class="dot"> . </span>
                        <span class="readtime">5 min read</span>
                      </p>
                      <h4 class="softwarehead">{{ obj.header }}</h4>
                      <h6 class="softwaresubhead">{{ obj.subtitile }}</h6>
                      <p>{{ obj.caption }}</p>
                      <p><a href="../blogs">Read More</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="activetab === '2'" class="tabcontent">
              <div class="container">
                <div class="row">
                  <div
                    class="col-lg-4 col-md-6 col-12 d-flex align-items-stretch"
                    v-for="(obj, key) in category1JSON"
                    :key="key"
                  >
                    <div class="icon-box">
                      <Image :fpath="obj.path" class="serviceimage" />
                      <p class="d-flex">
                        <span class="category">{{ obj.category }}</span>
                        <span class="dot"> . </span>
                        <span class="readtime">5 min read</span>
                      </p>
                      <h4 class="softwarehead">{{ obj.header }}</h4>
                      <h6 class="softwaresubhead">{{ obj.subtitile }}</h6>
                      <p>{{ obj.caption }}</p>
                      <p><a href="../blogs">Read More</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="activetab === '3'" class="tabcontent">
              <div class="container">
                <div class="row">
                  <div
                    class="col-lg-4 col-md-6 col-12 d-flex align-items-stretch"
                    v-for="(obj, key) in category2JSON"
                    :key="key"
                  >
                    <div class="icon-box">
                      <Image :fpath="obj.path" class="serviceimage" />
                      <p class="d-flex">
                        <span class="category">{{ obj.category }}</span>
                        <span class="dot"> . </span>
                        <span class="readtime">5 min read</span>
                      </p>
                      <h4 class="softwarehead">{{ obj.header }}</h4>
                      <h6 class="softwaresubhead">{{ obj.subtitile }}</h6>
                      <p>{{ obj.caption }}</p>
                      <p><a href="../blogs">Read More</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="activetab === '4'" class="tabcontent">
              <div class="container">
                <div class="row">
                  <div
                    class="col-lg-4 col-md-6 col-12 d-flex align-items-stretch"
                    v-for="(obj, key) in category3JSON"
                    :key="key"
                  >
                    <div class="icon-box">
                      <Image :fpath="obj.path" class="serviceimage" />
                      <p class="d-flex">
                        <span class="category">{{ obj.category }}</span>
                        <span class="dot"> . </span>
                        <span class="readtime">5 min read</span>
                      </p>
                      <h4 class="softwarehead">{{ obj.header }}</h4>
                      <h6 class="softwaresubhead">{{ obj.subtitile }}</h6>
                      <p>{{ obj.caption }}</p>
                      <p><a href="../blogs">Read More</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <NewsLetter />
</template>

<script>
import Image from "@/components/Image.vue";
import NewsLetter from "@/components/NewsLetter.vue";
export default {
  components: { Image, NewsLetter },
  data: () => ({
    activetab: "1",
    viewAllJSON: [
      {
        path: "Airline.jpg",
        category: "Analytics",
        header: "Airline Traffic Analytics",
        caption:
          "International airline traffic analytics dashboard provides a quick look at KPI’s related to aviation market in Australia. Metrics include key growth parameters and long term patterns related to Passengers & Cargo arriving from and departing to various destinations across the world.",
      },
      {
        path: "Time-Series.jpg",
        category: "Analytics",
        header: "Time Series Analysis – International Air Traffic",
        caption:
          "Time series analysis, a popular and effective tool to analyze historical data, identify patterns and predict the future trends. “Air Passengers” is a popular measure considered in many research articles pertaining to time series analysis and validating effectiveness of prediction output.",
      },
      {
        path: "Airport.jpg",
        category: "Analytics",
        header: "Airport Traffic Movement Analysis",
        caption:
          "Air Traffic is driven by demand for passengers as well as cargo, but the majority of revenue generated by airlines are through transporting passengers from source to destination. This demo looks at traffic patterns on annual basis for operating airports in Australia.",
      },
      {
        path: "Airline.jpg",
        category: "Analytics",
        header: "Airline Traffic Analytics",
        caption:
          "International airline traffic analytics dashboard provides a quick look at KPI’s related to aviation market in Australia. Metrics include key growth parameters and long term patterns related to Passengers & Cargo arriving from and departing to various destinations across the world.",
      },
      {
        path: "Time-Series.jpg",
        category: "Analytics",
        header: "Time Series Analysis – International Air Traffic",
        caption:
          "Time series analysis, a popular and effective tool to analyze historical data, identify patterns and predict the future trends. “Air Passengers” is a popular measure considered in many research articles pertaining to time series analysis and validating effectiveness of prediction output.",
      },
      {
        path: "Airport.jpg",
        category: "Analytics",
        header: "Airport Traffic Movement Analysis",
        caption:
          "Air Traffic is driven by demand for passengers as well as cargo, but the majority of revenue generated by airlines are through transporting passengers from source to destination. This demo looks at traffic patterns on annual basis for operating airports in Australia.",
      },
      {
        path: "Airline.jpg",
        category: "Analytics",
        header: "Airline Traffic Analytics",
        caption:
          "International airline traffic analytics dashboard provides a quick look at KPI’s related to aviation market in Australia. Metrics include key growth parameters and long term patterns related to Passengers & Cargo arriving from and departing to various destinations across the world.",
      },
      {
        path: "Time-Series.jpg",
        category: "Analytics",
        header: "Time Series Analysis – International Air Traffic",
        caption:
          "Time series analysis, a popular and effective tool to analyze historical data, identify patterns and predict the future trends. “Air Passengers” is a popular measure considered in many research articles pertaining to time series analysis and validating effectiveness of prediction output.",
      },
      {
        path: "Airport.jpg",
        category: "Analytics",
        header: "Airport Traffic Movement Analysis",
        caption:
          "Air Traffic is driven by demand for passengers as well as cargo, but the majority of revenue generated by airlines are through transporting passengers from source to destination. This demo looks at traffic patterns on annual basis for operating airports in Australia.",
      },
    ],
    category1JSON: [
      {
        path: "Airline.jpg",
        category: "Analytics",
        header: "Airline Traffic Analytics",
        caption:
          "International airline traffic analytics dashboard provides a quick look at KPI’s related to aviation market in Australia. Metrics include key growth parameters and long term patterns related to Passengers & Cargo arriving from and departing to various destinations across the world.",
      },
      {
        path: "Time-Series.jpg",
        category: "Analytics",
        header: "Time Series Analysis – International Air Traffic",
        caption:
          "Time series analysis, a popular and effective tool to analyze historical data, identify patterns and predict the future trends. “Air Passengers” is a popular measure considered in many research articles pertaining to time series analysis and validating effectiveness of prediction output.",
      },
      {
        path: "Airport.jpg",
        category: "Analytics",
        header: "Airport Traffic Movement Analysis",
        caption:
          "Air Traffic is driven by demand for passengers as well as cargo, but the majority of revenue generated by airlines are through transporting passengers from source to destination. This demo looks at traffic patterns on annual basis for operating airports in Australia.",
      },
    ],
    category2JSON: [
      {
        path: "Airline.jpg",
        category: "Analytics",
        header: "Airline Traffic Analytics",
        caption:
          "International airline traffic analytics dashboard provides a quick look at KPI’s related to aviation market in Australia. Metrics include key growth parameters and long term patterns related to Passengers & Cargo arriving from and departing to various destinations across the world.",
      },
      {
        path: "Time-Series.jpg",
        category: "Analytics",
        header: "Time Series Analysis – International Air Traffic",
        caption:
          "Time series analysis, a popular and effective tool to analyze historical data, identify patterns and predict the future trends. “Air Passengers” is a popular measure considered in many research articles pertaining to time series analysis and validating effectiveness of prediction output.",
      },
    ],
    category3JSON: [
      {
        path: "Airline.jpg",
        category: "Analytics",
        header: "Airline Traffic Analytics",
        caption:
          "International airline traffic analytics dashboard provides a quick look at KPI’s related to aviation market in Australia. Metrics include key growth parameters and long term patterns related to Passengers & Cargo arriving from and departing to various destinations across the world.",
      },
    ],
  }),
};
</script>

<style scoped>
span.dot {
  padding: 0px 0px 0px 10px;
}
.icon-box:hover {
  background: #f7f9fd;
  cursor: pointer;
  border-color: #0880e8;
}
a.tabsHeading.active {
  color: #0880e8;
  cursor: default;
}
img.serviceimage {
  width: 100%;
  padding-bottom: 15px;
  border-radius: 15px;
}
.icon-box {
  margin-bottom: 20px;
  background: #fff;
  border-radius: 15px;
  padding: 0px 0px 20px 0px;
  border: 1px solid #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.icon-box h4 {
  font-weight: 700;
  font-size: 15px;
  padding: 0px 20px;
  color: #0880e8;
  line-height: 1.5em;
}
.content {
  padding: 30px 0px;
}
.icon-box a {
  color: #0880e8;
}
.icon-box p {
  font-size: 16px;
  padding: 0px 20px;
}
.category {
  padding: 5px 10px;
}
.readtime {
  padding: 5px 10px;
}
.tabsblogs {
  text-align: center;
  margin-bottom: 8px;
}
.tabsHeading {
  text-align: center;
  padding: 10px 20px;
  font-size: 20px;
}
.blogposts {
  padding: 130px 40px 50px 40px;
}
@media only screen and (min-width: 0px) and (max-width: 998px) {
  .blogposts {
    padding: 20px !important;
  }
}
@media only screen and (min-width: 350px) and (max-width: 451px) {
  .tabsHeading {
    padding: 5px;
    font-size: 12px;
  }
  .tabsblogs {
    margin-bottom: 2px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 351px) {
  .tabsHeading {
    padding: 3px;
    font-size: 10px;
  }
  .tabsblogs {
    margin-bottom: 1px;
  }
}
</style>
