<template>
  <Header />
  <AllBlogs />
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import AllBlogs from "@/components/Blogposts/AllBlogs.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    AllBlogs,
    Footer,
  },
};
</script>
